
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'

import { ethers } from 'ethers'
import { IEcosystem, EcosystemId, ECOSYSTEMS, ChainId } from '@/ecosystem'

import {
    ERC20_ABI,
    PADSWAP_PAIR_ABI,
    SWAP_ROUTER_ABI,
    SWAP_FACTORY_ABI,
    APPROVE_AMOUNT
} from '@/constants'

import {
    WHITELIST,
    DEFAULT_SWAP_ROUTES
} from '@/config/swap_token_whitelist'

import { tokenInfo } from '@/mixins/tokenInfo.ts'
import TokenSelector from '@/components/swap/TokenSelector.vue'

const routerAddresses = {
  56: '0x76437234D29f84D9A12820A137c6c6A719138C24', // BSC
  1284: '0x40F1fEF0Fe68Fd10ff904070ee00a7769EE7fe34', // Moonbeam
  1285: '0x790d4b443edB9ce9A8d1aEC585edd89E51132D2c' // Moonriver
}

export default Vue.extend({
    name: 'AddLiquidity',
    components: { 
        TokenSelector,
    },
    mixins: [tokenInfo],
    data() {
      return {
        slippageTolerance: <string> '1',
        
        isEstimationLoading: <boolean> false,

        pairsOwnedByUser: <any> [],
        loadingPairsOwnedByUser: <boolean> true,

        inputToken: <any> {},
        outputToken: <any> {},

        tokenA: <any> {},
        tokenB: <any> {},

        amountTokenA: <string> '0',
        amountTokenB: <string> '0',

        amountToWithdraw: <string> '',


        inputTokenAllowance: <string> '0',
        outputTokenAllowance: <string> '0',

        balanceTokenA: <string> '0',
        balanceTokenB: <string> '0',

        allowanceTokenA: <string> '0',
        allowanceTokenB: <string> '0',

        exactToken: '',

        // Checked while updating the token estimation.
        // Set to false if the pair for these two tokens doesn't already exist.
        // If false, a warning will be shown about being the first liquidity provider.
        pairAlreadyExists: <boolean> true,

        estimationMode: 0,

        tokenWhitelist: <any> [],

        tokenSelectionDialog: <boolean> false,
        selectedTokenAddress: <string> '',

        routerContractAddress: <string> '0x40F1fEF0Fe68Fd10ff904070ee00a7769EE7fe34',

        inputTokenAddress: <string> '0x59193512877E2EC3bB27C178A8888Cfac62FB32D',
        outputTokenAddress: <string> '0xF480f38C366dAaC4305dC484b2Ad7a496FF00CeA'
      }
    },
    created() {
      this.initializeForCurrentChain()
      setInterval(this.updateTokenBalances, 3000)
    },
    computed: {
        ecosystemId: {
          get(): EcosystemId {
            return this.$store.state.ecosystemId
          },
          set(val: EcosystemId) {
            this.$store.commit('setEcosystemId', val)
          }
        },
        userAddress(): string {
          return this.$store.state.address
        },
        multicall(): ethers.providers.Provider {
          return this.$store.getters.multicall
        },
        ecosystem(): IEcosystem {
          return this.$store.getters.ecosystem
        },
        web3(): ethers.Signer | null {
          return this.$store.state.web3
        },
        chainId(): ChainId {
          return this.$store.getters.ecosystem.chainId
        },
        isApprovedTokenA(): boolean {
          if ((parseFloat(this.amountTokenA) > 0 && parseFloat(this.allowanceTokenA) < parseFloat(this.amountTokenA))) {
            return false
          }
          else {
            return true
          }
        },
        isApprovedTokenB(): boolean {
          if ((parseFloat(this.amountTokenB) > 0 && parseFloat(this.allowanceTokenB) < parseFloat(this.amountTokenB))) {
            return false
          }
          else {
            return true
          }
        },
    },
    watch: {
        tokenA() {
            this.updateTokenBalances()
            this.updateEstimation()
        },
        tokenB() {
            this.updateTokenBalances()
            this.updateEstimation()
        },
        amountTokenA() {
          this.updateEstimation()
        },
        amountTokenB() {
          this.updateEstimation()
        },
        estimationMode() {
          this.updateEstimation()
        },
        userAddress() {
          this.updateTokenBalances()
        },
        chainId() { // Re-initializing after changing chain
          this.initializeForCurrentChain()
        }
    },
    methods: {
      // Called on initialization
      // and when switching to another chain
      initializeForCurrentChain() {
        this.routerContractAddress = routerAddresses[this.chainId]
        this.estimationMode = 0
        this.amountTokenA = ''
        this.amountTokenB = ''
        this.updateTokenWhitelist()
        this.setDefaultRoute()
        setTimeout(this.updateTokenBalances, 200)
      },

        getCardStyle() {
          let ecosystemBackgrounds = {
            56: "bg bg-bsc",
            1284: "bg bg-moonbeam",
            1285: "bg bg-moonriver"
          }

          const backgroundClass = ecosystemBackgrounds[this.chainId]
          
          return backgroundClass
        },

        setDefaultRoute() {
            var currentChainDefaults = DEFAULT_SWAP_ROUTES[this.chainId]
            this.tokenA = currentChainDefaults.inputToken
            this.tokenB = currentChainDefaults.outputToken
        },

        setMaxA() {
          this.amountTokenA = this.balanceTokenA
        },

        setMaxB() {
          this.amountTokenB = this.balanceTokenB
        },


        updateTokenWhitelist() {
            var currentChainWhitelist = WHITELIST[this.chainId]
            this.tokenWhitelist = currentChainWhitelist
        },


        async updateTokenA(newToken : any) {
          if (newToken.address == this.tokenB.address) {
            this.tokenB = this.tokenA
          }
          this.tokenA = newToken
        },


        async updateTokenB(newToken : any) {
          if (newToken.address == this.tokenA.address) {
            this.tokenA = this.tokenB
          }
          this.tokenB = newToken
        },


        async getDecimals(tokenContractAddress : string) {
          if (tokenContractAddress == 'eth') {
            return 18
          }
          else {
            let tokenContract = new ethers.Contract(tokenContractAddress, ERC20_ABI, this.multicall)
            const decimals = await tokenContract.decimals()
            return decimals
          }
        },

        async updateEstimation() {
          this.isEstimationLoading = true

          // Not doing anything if the input amount is zero
          if ((parseFloat(0 + this.amountTokenA) == 0 && this.estimationMode == 0)) {
            // this.amountTokenB = ''
            this.isEstimationLoading = false
            return
          }
          if ((parseFloat(0 + this.amountTokenB) == 0 && this.estimationMode == 1)) {
            // this.amountTokenA = ''
            this.isEstimationLoading = false
            return
          }

          const routerContract = new ethers.Contract(this.routerContractAddress, SWAP_ROUTER_ABI, this.multicall)

          const factoryContractAddress = await routerContract.factory()
          const factoryContract = new ethers.Contract(factoryContractAddress, SWAP_FACTORY_ABI, this.multicall)

          const weth = await routerContract.WETH()

          let inputToken = this.tokenA.address
          if (inputToken == 'eth') {
            inputToken = weth
          }
   
          let outputToken = this.tokenB.address
          if (outputToken == 'eth') {
            outputToken = weth
          }

          const decimalsIn = await this.getDecimals(inputToken)
          const decimalsOut = await this.getDecimals(outputToken)

          // Checking if the pair for these two tokens already exists.
          // If it doesn't, then the amount of other token cannot be auto-estimated,
          // and a warning will be shown about being the first provider in that pool.
          let pairAddress : string = ""
          try {
            pairAddress = await factoryContract.getPair(inputToken, outputToken)
            this.pairAlreadyExists = true
          }
          catch (err) {
            this.pairAlreadyExists = false
            this.isEstimationLoading = false
            return
          }
          if (pairAddress == '0x0000000000000000000000000000000000000000') {
            this.pairAlreadyExists = false
            this.isEstimationLoading = false
            return
          }

          const pairContract = new ethers.Contract(pairAddress, PADSWAP_PAIR_ABI, this.multicall)

          // @ts-ignore-next-line
          const pairData = await this.getPairData(pairAddress)

          const reserves = await pairContract.getReserves()          

          const token0Amount : number = parseFloat(ethers.utils.formatUnits(reserves._reserve0, pairData.token0.decimals))
          const token1Amount : number = parseFloat(ethers.utils.formatUnits(reserves._reserve1, pairData.token1.decimals))

          let estimationTokenA = token0Amount / token1Amount // How much tokenA you will get for one tokenB
          let estimationTokenB = token1Amount / token0Amount // How much tokenB you will get for one tokenA
          
          if (pairData.token0.address.toLowerCase() != inputToken.toLowerCase()) {
            const tmp = estimationTokenB
            estimationTokenB = estimationTokenA
            estimationTokenA = tmp
          }


          if (this.estimationMode == 1) {
            const totalEstimation = estimationTokenA * parseFloat(this.amountTokenB)
            this.amountTokenA = (totalEstimation.toFixed(decimalsIn)).toString()
          }

          else {
            const totalEstimation = estimationTokenB * parseFloat(this.amountTokenA)
            this.amountTokenB = (totalEstimation.toFixed(decimalsOut)).toString()
          }

          this.isEstimationLoading = false
        },



        switchSelectedTokens() {
          var tmp = this.tokenA
          this.tokenA = this.tokenB
          this.tokenB = tmp
        },


        async updateTokenBalances() {
           if (!this.web3){
            this.balanceTokenA = '0'
            this.balanceTokenB = '0'
            return
          }

          // @ts-ignore-next-line
          const pBalanceA = this.getTokenBalance(this.tokenA.address)
          // @ts-ignore-next-line
          const pBalanceB = this.getTokenBalance(this.tokenB.address)
          // @ts-ignore-next-line
          const pAllowanceA = this.getTokenAllowance(this.tokenA.address, this.routerContractAddress)
          // @ts-ignore-next-line
          const pAllowanceB = this.getTokenAllowance(this.tokenB.address, this.routerContractAddress)

          this.balanceTokenA = await pBalanceA
          this.balanceTokenB = await pBalanceB
          this.allowanceTokenA = await pAllowanceA
          this.allowanceTokenB = await pAllowanceB
        },


        async addLiquidity() {
          if (this.tokenA.address == 'eth' || this.tokenB.address == 'eth') {
            this.addLiquidityETH()
          }
          else {
            this.addLiquidityTokens()
          }
        },

        async removeLiquidity() {

        },


        // Approves either tokenA or tokenB
        // Takes 'tokenA' or 'tokenB' as argument
        async approve(tokenID : string) {
          let tokenAddress : string
          if (tokenID == 'tokenA') {
            tokenAddress = this.tokenA.address
          }
          else {
            tokenAddress = this.tokenB.address
          }

          const inputTokenContract = new ethers.Contract(tokenAddress, ERC20_ABI, this.multicall)

          const tx = await inputTokenContract.populateTransaction.approve(this.routerContractAddress, APPROVE_AMOUNT)
          await this.safeSendTransaction({ tx, targetChainId: this.ecosystem.chainId})
        },


        // Adds TokenA-TokenB liquidity
        async addLiquidityTokens() {
          const routerContract = new ethers.Contract(this.routerContractAddress, SWAP_ROUTER_ABI, this.multicall)

          const amountADesired = ethers.utils.parseEther(this.amountTokenA)
          const amountBDesired = ethers.utils.parseEther(this.amountTokenB)

          const minimumPercentage = 100 - parseInt(this.slippageTolerance)

            const amountAMin = amountADesired.mul(minimumPercentage).div(100)
            const amountBMin = amountBDesired.mul(minimumPercentage).div(100)

            const tx = await routerContract.populateTransaction.addLiquidity(
              this.tokenA.address,
              this.tokenB.address,
              amountADesired,
              amountBDesired,
              amountAMin,
              amountBMin,
              this.userAddress,
              Date.now() + 1000 * 60 * 10)

            const txReceipt: ethers.providers.TransactionReceipt | false = await this.safeSendTransaction({ tx, targetChainId: this.chainId })
        },


        // Adds TokenA-ETH liquidity
        async addLiquidityETH() {
            if (this.tokenA.address != 'eth' && this.tokenB.address == 'eth') {
              this.switchSelectedTokens() // Making sure that tokenA is the eth token
            }

            const routerContract = new ethers.Contract(this.routerContractAddress, SWAP_ROUTER_ABI, this.multicall)

            const amountETHDesired = ethers.utils.parseEther(this.amountTokenA)
            const amountTokenDesired = ethers.utils.parseEther(this.amountTokenB)

            const minimumPercentage = 100 - parseInt(this.slippageTolerance)

            const amountETHMin = amountETHDesired.mul(minimumPercentage).div(100)
            const amountTokenMin = amountTokenDesired.mul(minimumPercentage).div(100)

            const tx = await routerContract.populateTransaction.addLiquidityETH(
              this.tokenB.address,
              amountTokenDesired,
              amountTokenMin,
              amountETHMin,
              this.userAddress,
              Date.now() + 1000 * 60 * 10)

            tx.value = amountETHDesired

            const txReceipt: ethers.providers.TransactionReceipt | false = await this.safeSendTransaction({ tx, targetChainId: this.chainId })
        },

        ...mapGetters(['isConnected']),
        ...mapActions(['requestConnect', 'safeSendTransaction'])
    }
})

