
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import { ethers } from 'ethers'
import { tokenInfo } from '@/mixins/tokenInfo'

import {
  ERC20_ABI,
  SWAP_ROUTER_ABI,
  SWAP_FACTORY_ABI,
  PADSWAP_PAIR_ABI,
  PADSWAP_FARM_ABI,
  FARM_REQUIRED_ALLOWANCE,
  PADSWAP_SINGLE_STAKE_FARM_ABI,
  PADSWAP_LP_FARM_ABI,
  APPROVE_AMOUNT } from '@/constants'
import { IEcosystem, EcosystemId, ECOSYSTEMS, ChainId } from '@/ecosystem'
import { formatMixin } from '@/format'
import { FarmData } from '@/types'
import { delay, equalsInsensitive, toFloat } from '@/utils'

type ValidationStatus = {
  status: boolean,
  message?: string
}


const routerAddresses = {
  56: '0x76437234D29f84D9A12820A137c6c6A719138C24', // BSC
  1284: '0x40F1fEF0Fe68Fd10ff904070ee00a7769EE7fe34', // Moonbeam
  1285: '0x790d4b443edB9ce9A8d1aEC585edd89E51132D2c' // Moonriver
}

export default Vue.extend({
  name: 'LiquidityPairWidget',
  mixins: [formatMixin, tokenInfo],
  props: {
    pairData : Object,
    pairChainId : Number,
  },
  data() {
    return {
      removeAmount: <string> '',
    }
  },
  created() {
    setInterval(() => {
          this.updateAllowance()
      }, 3000)
  },
  computed: {
    ecosystem(): IEcosystem {
      // @ts-ignore-next-line
      return ECOSYSTEMS[this.ecosystemId as EcosystemId]
    },
    userAddress(): string {
      return this.$store.state.address
    },
    userLpBalanceNum(): number {
      // @ts-ignore-next-line
      return parseFloat(ethers.utils.formatEther(this.userLpBalance ?? 0))
    },
    userStakedBalanceNum(): number {
      // @ts-ignore-next-line
      return parseFloat(ethers.utils.formatEther(this.userStakedBalance ?? 0))
    },
    multicall(): ethers.providers.Provider {
      return this.$store.getters.multicall
    },
    removeAmountBn: {
      get(): ethers.BigNumber {
        return ethers.utils.parseEther(this.removeAmount || '0')
      },
      set(val: ethers.BigNumber) {
        this.removeAmount = ethers.utils.formatEther(val)
      }
    },
    validationStatus(): ValidationStatus {
      if (this.removeAmount == null) {
        return { status: false }
      }

      return { status: true }
    },
    farmDataMinWidths(): string[] {
      if (this.$vuetify.breakpoint.xl) {
        return ['200px', '200px', '200px']
      } else if (this.$vuetify.breakpoint.lg) {
        return ['130px', '130px', '200px']
      } else if (this.$vuetify.breakpoint.md) {
        return ['100px', '100px', '200px']
      } else {
        return ['initial', 'initial', 'initial']
      }
    },
    chainId(): ChainId {
      return this.$store.getters.ecosystem.chainId
    },
    routerContractAddress() : string {
      // @ts-ignore-next-line
      return routerAddresses[this.chainId]
    },
    isSpendingApproved() : boolean {
      if (!(parseFloat(this.removeAmount!.toString()) > 0)) {
        return true
      }
      else {
        const allowance = ethers.utils.formatEther(this.pairData.allowance)
        return parseFloat(this.removeAmount!.toString()) <= parseFloat(allowance)
      }
    },
    ...mapState(['web3'])
  },
  methods: {
    async updateAllowance() {
      const pairContract = new ethers.Contract(this.pairData.address, PADSWAP_PAIR_ABI, this.multicall)
      const allowance = await pairContract.allowance(this.userAddress, this.routerContractAddress)
      this.pairData.allowance = allowance
    },

    round(num : any, dec : any) {
      num = Number(num).toFixed(20)
      if(!Number.isFinite(Number(num))) num = '0.0'
      num = Number(num).toFixed(20)
      const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${dec}})?`)
      let [int, decimals] = num.toString().replace(',', '.').split('.')
      if(dec == 0) return int
      const rounded = num.toString().match(regex)[0]
      return rounded
    },
    biOrMiOrK(num : number) : string {
      if(num>=1e9) return this.round(num/1e9, 2) + 'BI'
      else if(num>=1e6) return this.round(num/1e6, 2) + 'M'
      else if (num>=1e3) return this.round(num/1e3, 2) + 'K'
      else if (num>= 1e2) return this.round(num, 2)
      else if (num >= 1) return this.round(num, 4)
      else return this.round(num, 6)
    },
    setMax() {
      this.removeAmount = this.pairData.userBalance
    },
    async deposit() {
      
    },
    async withdraw() {
      // if (!this.validationStatus.status) {
      //   this.shakeButton('dwButton')
      //   return
      // }

      if (this.pairData.token0.address == 'eth' || this.pairData.token1.address == 'eth') {
        this.removeLiquidityETH()
      }
      else {
        this.removeLiquidityTokens()
      }
    },    


    // Removes TokenA-TokenB liquidity
    async removeLiquidityTokens() {
        const routerContract = new ethers.Contract(this.routerContractAddress, SWAP_ROUTER_ABI, this.multicall)

        const amountAMin = ethers.utils.parseEther('0')
        const amountBMin = ethers.utils.parseEther('0')

        const tx = await routerContract.populateTransaction.removeLiquidity(
          this.pairData.token0.address,
          this.pairData.token1.address,
          ethers.utils.parseEther(this.removeAmount!.toString()),
          amountAMin,
          amountBMin,
          this.userAddress,
          Date.now() + 1000 * 60 * 10)

        const txReceipt: ethers.providers.TransactionReceipt | false = await this.safeSendTransaction({ tx, targetChainId: this.chainId })
    },

    async approve() {
      const pairContract = new ethers.Contract(this.pairData.address, PADSWAP_PAIR_ABI, this.multicall)

      const tx = await pairContract.populateTransaction.approve(this.routerContractAddress, APPROVE_AMOUNT)
      const succeeded = await this.safeSendTransaction({ tx, targetChainId: this.chainId })
    },


    // Removes Token-ETH liquidity
    async removeLiquidityETH() {
      let token

      if (this.pairData.token0.address != 'eth') {
        token = this.pairData.token0
      }
      else {
        token = this.pairData.token1
      }

      const routerContract = new ethers.Contract(this.routerContractAddress, SWAP_ROUTER_ABI, this.multicall)

      const amountETHMin = ethers.utils.parseEther('0')
      const amountTokenMin = ethers.utils.parseEther('0')

      const tx = await routerContract.populateTransaction.removeLiquidityETHSupportingFeeOnTransferTokens(
        token.address,
        ethers.utils.parseEther(this.removeAmount!.toString()),
        amountTokenMin,
        amountETHMin,
        this.userAddress,
        Date.now() + 1000 * 60 * 10)

      const txReceipt: ethers.providers.TransactionReceipt | false = await this.safeSendTransaction({ tx, targetChainId: this.chainId })
    },
    ...mapActions(['safeSendTransaction'])
  }
})
