import { render, staticRenderFns } from "./AddLiquidity.vue?vue&type=template&id=713bc155&scoped=true&"
import script from "./AddLiquidity.vue?vue&type=script&lang=ts&"
export * from "./AddLiquidity.vue?vue&type=script&lang=ts&"
import style0 from "./AddLiquidity.vue?vue&type=style&index=0&id=713bc155&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "713bc155",
  null
  
)

export default component.exports